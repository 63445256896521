import React from 'react'
import { string, bool } from 'prop-types'
import styled from 'styled-components'
import { rgba, stripUnit } from 'polished'

import * as spacing from 'styles/spacing'
import { media } from 'styles/media'
import { grey, offWhite, white, primaryColor } from 'styles/colors'
import { fontSizes, fontFamilies } from 'styles/typography'

import Heading from 'components/Heading'

const Container = styled.div`
  display: block;
  padding: ${spacing.xLarge} ${spacing.large};
  padding-bottom: 0;
  background-color: ${offWhite};
  margin-bottom: 56px;

  ${media.small`
    padding: ${spacing.xxLarge};
    padding-bottom: 0;
    margin-bottom: 72px;
  `};

  ${media.medium`
    padding: 56px;
    display: flex;
    align-items: center;
    flex-direction: ${props => (props.isReverse ? 'row-reverse' : 'row')};
    margin-bottom: 112px;
  `};

  ${media.large`
    padding: 64px;
    margin-bottom: 146px;
  `};

  ${media.xLarge`
    padding: 72px;
    margin-bottom: 190px;
  `};
`

const StyledHeading = styled(Heading)`
  color: #4f7597;

  ${media.medium`
    font-size: 26px;
  `};

  ${media.large`
    margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
    font-size: 27px;
  `};

  ${media.xLarge`
    font-size: 28px;
  `};
`

const Content = styled.div`
  position: static;
  margin-bottom: -${spacing.small};

  ${media.small`
    position: static;
  `};

  ${media.medium`
    padding-left: ${props => (props.isReverse ? '40px' : '0')};
    padding-right: ${props => (!props.isReverse ? '40px' : '0')};
    margin-bottom: 0;
  `};

  ${media.large`
    padding-left: ${props => (props.isReverse ? '48px' : '0')};
    padding-right: ${props => (!props.isReverse ? '48px' : '0')};
  `};

  ${media.xLarge`
    padding-left: ${props => (props.isReverse ? '56px' : '0')};
    padding-right: ${props => (!props.isReverse ? '56px' : '0')};
  `};
`

const Description = styled.p`
  color: ${rgba(grey, 0.75)};
`

const QuoteWrap = styled.div`
  position: relative;
  top: ${spacing.xLarge};
  background-color: ${primaryColor};
  color: ${white};
  padding: ${spacing.large};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.small`
    top: ${spacing.xxLarge};
    padding: ${spacing.xLarge};
  `};

  ${media.medium`
    top: 0;
    padding: 48px;
    flex-shrink: 0;
    min-width: 450px;
    width: 52.5%;
    margin-bottom: -112px;
  `};

  ${media.large`
    padding: 56px;
    width: 520px;
    margin-bottom: -136px;
  `};

  ${media.xLarge`
    padding: 64px;
    width: 560px;
    height: 560px;
    margin-bottom: -182px;
    flex-shrink: 0;
  `};
`

const Quote = styled(Heading)`
  color: ${white};
  margin-bottom: ${spacing.medium};
  font-size: 18px;

  ${media.small`
    font-size: 19px;
    margin-bottom: ${spacing.large};
  `};

  ${media.medium`
    font-size: 20px;
    margin-bottom: ${spacing.large};
  `};

  ${media.large`
    font-size: 21px;
    margin-bottom: ${spacing.large};
  `};

  ${media.xLarge`
    margin-bottom: ${spacing.xLarge};
    font-size: 22px;
  `};
`

const Details = styled.div`
  font-size: ${fontSizes.small};
  font-family: ${fontFamilies.heading};
  text-transform: uppercase;
`

const Author = styled.span`
  display: block;
`

const Role = styled.span`
  display: block;
  color: ${rgba(white, 0.6)};
`

const Logo = styled.img`
  margin-top: ${spacing.medium};
  display: inline-block;
  height: 24px;

  ${media.medium`
    height: 28px;
  `};
`

const Testimonial = ({
  name,
  description,
  quote,
  author,
  role,
  company,
  isReverse,
}) => (
  <Container isReverse={isReverse}>
    <Content isReverse={isReverse}>
      <StyledHeading size={2}>{name}</StyledHeading>
      <Description>{description}</Description>
    </Content>
    <QuoteWrap>
      <Quote size={3}>“{quote}”</Quote>
      <Details>
        <Author>{author}</Author>
        <Role>{role}</Role>
        <Logo
          src={require(`assets/images/testimonials/${company}.png`)}
          alt={`${company} logo`}
        />
      </Details>
    </QuoteWrap>
  </Container>
)

Testimonial.propTypes = {
  name: string.isRequired,
  description: string.isRequired,
  quote: string.isRequired,
  author: string.isRequired,
  role: string.isRequired,
  company: string.isRequired,
  isReverse: bool,
}

export default Testimonial

import React from 'react'
import { node } from 'prop-types'
import Helmet from 'react-helmet'

import { GlobalStyle } from 'styles/global'
import Footer from 'components/Footer'

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    <Helmet title="SciFi VC" />
    {children}
    <Footer />
  </>
)

Layout.propTypes = {
  children: node.isRequired,
}

export default Layout

import React from 'react'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll'

import Wrapper from 'components/Wrapper'
import Section, { SectionBlock } from 'components/Section'
import Heading from 'components/Heading'
import TeamList from 'components/TeamList'

const Container = styled.div`
  position: static;
`

const Team = () => (
  <Container id="team">
    <Section>
      <Wrapper>
        <SectionBlock>
          <ScrollAnimation animateIn="fadeInUp" offset={0} animateOnce>
            <Heading size={5}>Our Team</Heading>
          </ScrollAnimation>
        </SectionBlock>

        <SectionBlock>
          <ScrollAnimation
            animateIn="fadeInUp"
            offset={0}
            delay={200}
            animateOnce
          >
            <TeamList
              members={[
                {
                  name: 'Nellie Levchin',
                  slug: 'nellie',
                  role: 'Partner',
                  link: 'https://www.linkedin.com/in/nellie-levchin-90b/',
                },
                {
                  name: 'Max Levchin',
                  slug: 'max',
                  role: 'Partner',
                  link: 'https://www.linkedin.com/in/maxlevchin/',
                },
                {
                  name: 'Brooks Hosfield',
                  slug: 'brooks',
                  role: 'Partner',
                  link: 'https://www.linkedin.com/in/brooks-hosfield-31102717/',
                },
                {
                  name: 'Matt Therian',
                  slug: 'matt',
                  role: 'Partner, Finance and Operations',
                  link: 'https://www.linkedin.com/in/mtherian/',
                },
                {
                  name: 'Mikaela Encarnacion',
                  slug: 'mikaela',
                  role: 'Executive Assistant',
                  link: 'https://www.linkedin.com/in/mikaela-encarnacion/',
                },
              ]}
            />
          </ScrollAnimation>
        </SectionBlock>
      </Wrapper>
    </Section>
  </Container>
)

export default Team

import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { media } from './media'
import { primaryColor, grey } from './colors'
import { animationTime, animationCurve } from './variables'

// Font family
export const fontFamilies = {
  display:
    "Futura Light, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
  body:
    "Apercu Light, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
  heading:
    "Apercu Mono, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
}

// Font weights
export const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 700,
}

export const lineHeights = {
  base: 1.4,
  heading: 1.25,
  tight: 1.05,
}

// Font sizes
export const fontSizes = {
  regular: '16px',
  small: '14px',
  tiny: '12px',
}

export const Lead = styled.p`
  font-size: 1.1em;

  ${media.medium`
    font-size: 1.2em;
  `};
`

export default css`
  body {
    font-family: ${fontFamilies.body};
    font-weight: ${fontWeights.regular};
    line-height: ${lineHeights.base};
    color: ${grey};
    font-size: ${fontSizes.regular};
  }

  small {
    font-size: ${fontSizes.small};
  }

  strong {
    font-weight: ${fontWeights.bold};
  }

  p {
    margin-bottom: 1.25em;

    &:last-child {
      margin-bottom: 0;
    }

    ${media.medium`
      margin-bottom: 1.5em;
    `};
  }

  p a {
    color: ${primaryColor};
    border-bottom: 1px solid ${rgba(primaryColor, 0.2)};
    transition: border-color ${animationTime} ${animationCurve};

    &:hover,
    &:focus {
      border-color: ${rgba(primaryColor, 0.4)};
    }
  }
`

import React from 'react'
import { node, bool } from 'prop-types'
import styled, { css } from 'styled-components'

import { media } from 'styles/media'
import { verticalPadding } from 'styles/helpers'

const Container = styled.section`
  display: block;
  ${verticalPadding`48px`};

  ${props =>
    props.flushTop &&
    css`
      padding-top: 0 !important;
    `}
  
  ${props =>
    props.flushBottom &&
    css`
      padding-bottom: 0 !important;
    `}

  ${media.small`
    ${verticalPadding`64px`};
  `};

  ${media.medium`
    ${verticalPadding`80px`};
  `};

  ${media.large`
    ${verticalPadding`96px`};
  `};

  ${media.xLarge`
    ${verticalPadding`120px`};
  `};
`

const Block = styled.div`
  position: relative;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.small`
    margin-bottom: 32px;
  `};

  ${media.medium`
    margin-bottom: 40px;
  `};

  ${media.large`
    margin-bottom: 48px;
  `};

  ${media.xLarge`
    margin-bottom: 56px;
  `};
`

const Section = ({ children, flushTop, flushBottom }) => (
  <Container flushTop={flushTop} flushBottom={flushBottom}>
    {children}
  </Container>
)

Section.propTypes = {
  children: node.isRequired,
  flushTop: bool,
  flushBottom: bool,
}

export const SectionBlock = ({ children }) => <Block>{children}</Block>

SectionBlock.propTypes = {
  children: node.isRequired,
}

export default Section

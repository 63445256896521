import React from 'react'
import styled from 'styled-components'
import { stripUnit } from 'polished'
import LazyLoad from 'react-lazyload'

import { greyLight } from 'styles/colors'
import { fontSizes, fontFamilies } from 'styles/typography'
import { cover } from 'styles/helpers'
import { animationTime, animationCurve } from 'styles/variables'
import { media, mediaDown } from 'styles/media'
import * as spacing from 'styles/spacing'

import Heading from 'components/Heading'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: ${spacing.large};

  ${media.medium`
    grid-template-columns: repeat(3, 1fr);
    row-gap: ${spacing.xLarge};
  `};

  ${media.large`
    row-gap: ${spacing.xxLarge};
  `};

  ${media.xLarge`
    row-gap: 48px;
  `};
`

const Item = styled.a`
  display: flex;
  flex-direction: column;
`

const PhotoWrap = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};
  background-color: #a5caee;

  ${media.medium`
    margin-bottom: ${spacing.medium};
  `};

  ${media.xLarge`
    margin-bottom: ${stripUnit(spacing.medium) * 1.25 + 'px'};
  `};

  &:after {
    content: '';
    display: block;
    padding-bottom: 118%;
  }
`

const Photo = styled.img`
  ${cover('absolute')};
  object-fit: cover;
  transition: opacity ${animationTime} ${animationCurve};

  &:first-child {
    z-index: 10;
  }

  ${Item}:hover &:first-child {
    opacity: 0;
  }
`

const Name = styled(Heading)`
  margin-bottom: ${stripUnit(spacing.tiny) * 0.5 + 'px'};

  ${mediaDown.small`
    font-size: 18px;
  `}

  ${media.small`
    margin-bottom: ${spacing.tiny};
  `};

  ${media.medium`
    margin-bottom: ${spacing.small};
  `};
`

const Role = styled.span`
  font-size: ${fontSizes.small};
  font-family: ${fontFamilies.heading};
  color: ${greyLight};
`

const Details = styled.div`
  padding-right: ${spacing.medium};
`

const TeamList = ({ members }) => (
  <Container>
    {members.map((member, i) => (
      <Item key={i} href={member.link} target="_blank">
        <PhotoWrap>
          <LazyLoad>
            <Photo
              src={require(`assets/images/team/${member.slug}.jpg`)}
              alt={`${member.name} portrait`}
            />
          </LazyLoad>
          <LazyLoad>
            <Photo
              src={require(`assets/images/team/${member.slug}-hover.jpg`)}
              alt=""
              role="presentation"
            />
          </LazyLoad>
        </PhotoWrap>
        <Details>
          <Name size={3}>{member.name}</Name>
          <Role>{member.role}</Role>
        </Details>
      </Item>
    ))}
  </Container>
)

export default TeamList

import React from 'react'

import Layout from 'layouts'
import Hero from 'components/home/Hero'
import Contact from 'components/home/Contact'
import Portfolio from 'components/home/Portfolio'
import About from 'components/home/About'
import Team from 'components/home/Team'
import MetaTags from 'components/MetaTags'
import Testimonials from 'components/home/Testimonials'

const Home = () => (
  <Layout>
    <MetaTags title="SciFi VC" />
    <Hero />
    <About />
    <Portfolio />
    <Testimonials />
    <Team />
    <Contact />
  </Layout>
)

export default Home

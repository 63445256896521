import React from 'react'
import styled from 'styled-components'

import { media } from 'styles/media'
import { verticalPadding } from 'styles/helpers'
import { fontSizes, fontWeights, fontFamilies } from 'styles/typography'
import { white } from 'styles/colors'

import Wrapper from 'components/Wrapper'

const currentYear = new Date().getFullYear()

const Container = styled.footer`
  background-color: #44769a;
  ${verticalPadding`24px`};

  ${media.small`
    ${verticalPadding`32px`};
  `};

  ${media.medium`
    ${verticalPadding`40px`};
  `};

  ${media.xLarge`
    ${verticalPadding`48px`};
  `};
`

const Copyright = styled.span`
  color: ${white};
  font-size: ${fontSizes.small};
  font-family: ${fontFamilies.heading};
  text-transform: uppercase;
  font-weight: ${fontWeights.medium};
`

const Footer = () => (
  <Container>
    <Wrapper>
      <Copyright>
        © {currentYear} SciFi VC // For all other inquiries, please get in touch
        – <a href="mailto:info@scifi.vc">info@scifi.vc</a>
      </Copyright>
    </Wrapper>
  </Container>
)

export default Footer

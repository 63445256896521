import { css } from 'styled-components'

export default css`
  @font-face {
    font-family: 'Apercu Mono';
    src: url('/fonts/apercu-mono.eot');
    src: url('/fonts/apercu-mono.eot?#iefix') format('embedded-opentype'),
      url('/fonts/apercu-mono.woff2') format('woff2'),
      url('/fonts/apercu-mono.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Apercu Light';
    src: url('/fonts/apercu-light.eot');
    src: url('/fonts/apercu-light.eot?#iefix') format('embedded-opentype'),
      url('/fonts/apercu-light.woff2') format('woff2'),
      url('/fonts/apercu-light.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Futura Light';
    src: url('/fonts/futura-light.eot');
    src: url('/fonts/futura-light.eot?#iefix') format('embedded-opentype'),
      url('/fonts/futura-light.woff2') format('woff2'),
      url('/fonts/futura-light.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
`

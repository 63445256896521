import React from 'react'
import styled, { css } from 'styled-components'
import { node, string } from 'prop-types'
import { Link } from 'gatsby'
import { mix, stripUnit } from 'polished'

import { primaryColor, white } from 'styles/colors'
import {
  animationTime,
  animationCurve,
  inputHeight,
  inputHeightDesktop,
  borderRadius,
} from 'styles/variables'
import { fontFamilies, fontSizes } from 'styles/typography'
import * as spacing from 'styles/spacing'
import { media } from 'styles/media'

export const Container = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${inputHeight};
  border-radius: ${borderRadius};
  padding: 0 ${spacing.large};
  cursor: pointer;
  text-transform: uppercase;
  font-family: ${fontFamilies.heading};
  font-size: ${fontSizes.small};
  text-align: center;
  transition: all ${animationTime} ${animationCurve};

  ${media.medium`
    height: ${inputHeightDesktop};
  `};

  ${media.large`
    padding: 0 ${spacing.xLarge};
  `};

  ${props =>
    props.size === 'small' &&
    css`
      height: ${stripUnit(inputHeight) * 0.75 + 'px'};
      padding: 0 ${spacing.medium};

      ${media.medium`
        height: ${stripUnit(inputHeightDesktop) * 0.75 + 'px'};
        padding: 0 ${spacing.large};
      `};
    `}

  ${props =>
    props.theme === 'primary' &&
    css`
      color: #3a5f80;
      background-color: #bedcf8;

      &:hover,
      &:focus,
      &:active {
        background-color: ${mix(0.85, '#BEDCF8', white)};
      }
    `}

  ${props =>
    props.theme === 'secondary' &&
    css`
      color: ${white};
      border: 2px solid ${white};

      &:hover,
      &:focus,
      &:active {
        color: ${primaryColor};
        background-color: ${white};
      }
    `}
    
  &[disabled] {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
  }
`

const getElement = props => {
  if (props.to) return Link
  if (props.href) return 'a'
  return 'button'
}

const Button = ({
  children,
  theme = 'primary',
  size,
  type = 'button',
  ...rest
}) => (
  <Container theme={theme} size={size} as={getElement(rest)} {...rest}>
    {children}
  </Container>
)

Button.propTypes = {
  children: node.isRequired,
  theme: string,
  size: string,
}

export default Button

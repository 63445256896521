import React from 'react'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll'

import * as spacing from 'styles/spacing'
import { media } from 'styles/media'

import Wrapper from 'components/Wrapper'
import Section, { SectionBlock } from 'components/Section'
import Heading from 'components/Heading'
import Testimonial from 'components/Testimonial'

const Content = styled.div`
  position: static;
  margin-bottom: -${spacing.large};

  ${media.small`
    margin-bottom: -${spacing.xLarge};
  `};

  ${media.medium`
    margin-bottom: -${spacing.xxxLarge};
  `};

  ${media.large`
    margin-bottom: -74px;
  `};

  ${media.xLarge`
    margin-bottom: -82px;
  `};
`

const Testimonials = () => (
  <div id="testimonials">
    <Section flushBottom>
      <Wrapper>
        <SectionBlock>
          <ScrollAnimation animateIn="fadeInUp" offset={0} animateOnce>
            <Heading size={5}>How We Partner</Heading>
          </ScrollAnimation>
        </SectionBlock>

        <Content>
          <SectionBlock>
            <ScrollAnimation
              animateIn="fadeInUp"
              offset={0}
              delay={200}
              animateOnce
            >
              <Testimonial
                name="Brainstorm Stage"
                description="We partner with ambitious founders exploring fintech, marketplaces, and other areas at the earliest stages of company formation. We work together with founders to define product and strategy, before leading the seed round."
                quote="Max and Alex at SciFi were critical in helping us during the earliest, formative stages of the company. They were excited to get into the details, pull in people from their network to advise, and even dug into spreadsheets and models with us. Grateful for the help - and excited to be partners for a long time to come."
                author="Sadi Khan"
                role="Founder and CEO of Aven"
                company="aven"
              />
            </ScrollAnimation>
          </SectionBlock>

          <SectionBlock>
            <ScrollAnimation
              animateIn="fadeInUp"
              offset={0}
              delay={400}
              animateOnce
            >
              <Testimonial
                name="Early Stage"
                description="We participate in Seed and Series A rounds for companies bringing innovative products to massive markets. Our philosophy is to bring unprecedented intellectual rigor to early stage investing, and deep domain expertise to supporting our portfolio companies through critical product, strategy, and fundraising decisions."
                quote="SciFi was our first investor and Brex wouldn't be Brex without them. Max's and the team’s knowledge around fintech really helped us innovate and structure the company in a scalable way. They were helpful around fundraising (debt & equity), partnerships, credit, and operating advice."
                author="Henrique Dubugras"
                role="Founder and CEO of Brex"
                company="brex"
                isReverse
              />
            </ScrollAnimation>
          </SectionBlock>

          <SectionBlock>
            <ScrollAnimation
              animateIn="fadeInUp"
              offset={0}
              delay={600}
              animateOnce
            >
              <Testimonial
                name="Growth Stage"
                description="In addition to making follow on investments in our brainstorm and early stage portfolio companies, we opportunistically invest in later stage companies with exceptional growth."
                quote="SciFi has been a great partner in our initial and growth rounds. Nellie, Max, and Alex have a unique understanding of both marketplaces and lending, from unit economics to capital markets. As an investor, they consistently bring the value of a larger fund."
                author="Mate Pencz"
                role="Founder and Co-CEO of Loft"
                company="loft"
              />
            </ScrollAnimation>
          </SectionBlock>
        </Content>
      </Wrapper>
    </Section>
  </div>
)

export default Testimonials

import React from 'react'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll'

import { media } from 'styles/media'
import { primaryColor, white } from 'styles/colors'
import { verticalPadding } from 'styles/helpers'

import Wrapper from 'components/Wrapper'
import Heading from 'components/Heading'
import Section from 'components/Section'
import Button from 'components/button/Button'

const Container = styled.div`
  background-color: ${primaryColor};
  ${verticalPadding`16px`};

  ${media.small`
    ${verticalPadding`24px`};
  `};

  ${media.medium`
    ${verticalPadding`32px`};
  `};

  ${media.large`
    ${verticalPadding`40px`};
  `};

  ${media.xLarge`
    ${verticalPadding`48px`};
  `};
`

const Inner = styled.div`
  position: static;

  ${media.small`
    width: 90%;
  `};

  ${media.medium`
    width: 80%;
  `};
`

const Title = styled(Heading)`
  margin-bottom: 1em;
  color: ${white};
`

const Contact = () => (
  <Container id="contact">
    <Section>
      <Wrapper>
        <Inner>
          <Title size={1}>
            <ScrollAnimation animateIn="fadeInUp" offset={0} animateOnce>
              We’d love to hear from you if you are solving a hard, valuable
              problem.
            </ScrollAnimation>
          </Title>
          <ScrollAnimation
            animateIn="fadeInUp"
            offset={0}
            delay={200}
            animateOnce
          >
            <Button href="mailto:pitch@scifi.vc" theme="primary">
              Get in touch
            </Button>
          </ScrollAnimation>
        </Inner>
      </Wrapper>
    </Section>
  </Container>
)

export default Contact

import React, { useState, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { rgba } from 'polished'

import { media } from 'styles/media'
import { fontSizes, fontFamilies } from 'styles/typography'
import { boxShadow } from 'styles/helpers'
import { white, greyDark } from 'styles/colors'
import {
  navHeight,
  navHeightMobile,
  animationTime,
  animationCurve,
} from 'styles/variables'
import * as spacing from 'styles/spacing'

import Logo from 'assets/images/logo.svg'
import Button from 'components/button/Button'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${navHeightMobile};
  padding: 0 ${spacing.large};
  transition: background-color ${animationTime} ${animationCurve},
    box-shadow ${animationTime} ${animationCurve};
  z-index: 400;

  ${props =>
    props.isFixed &&
    css`
      position: fixed;
      background-color: ${rgba(white, 0.98)};
      ${boxShadow};
    `}

  ${media.small`
    padding-left: 5%;
    padding-right: 5%;
  `};

  ${media.large`
    padding-left: 2.5%;
    padding-right: 2.5%;
  `};

  ${media.medium`
    height: ${navHeight};
  `};
`

const LogoWrap = styled(AnchorLink)`
  display: flex;
  align-items: center;
`

const StyledLogo = styled(Logo)`
  display: block;
  width: 90px;
  height: 21px;
  fill: ${white};
  transition: fill ${animationTime} ${animationCurve};

  ${props =>
    props.isDark &&
    css`
      fill: ${greyDark};
    `}

  ${media.medium`
    width: 100px;
    height: 23px;
  `};

  ${media.large`
    width: 116px;
    height: 27px;
  `};
`

const List = styled.div`
  display: flex;
  align-items: center;
`

const Item = styled(AnchorLink)`
  color: ${white};
  font-size: ${fontSizes.small};
  font-family: ${fontFamilies.heading};
  padding: ${spacing.small} 0;
  text-transform: uppercase;
  transition: color ${animationTime} ${animationCurve};

  ${props =>
    props.isDark &&
    css`
      color: ${greyDark};
    `}

  margin-left: ${spacing.xLarge};

  ${media.large`
    margin-left: ${spacing.xxLarge};
  `};
`


const OffsiteItem = styled.a`
  color: ${white};
  font-size: ${fontSizes.small};
  font-family: ${fontFamilies.heading};
  padding: ${spacing.small} 0;
  text-transform: uppercase;
  transition: color ${animationTime} ${animationCurve};

  ${props =>
    props.isDark &&
    css`
      color: ${greyDark};
    `}

  margin-left: ${spacing.xLarge};

  ${media.large`
    margin-left: ${spacing.xxLarge};
  `};
`

const Nav = () => {
  const [fixedNav, setFixedNav] = useState(false)

  const handleScroll = useCallback(() => {
    window.pageYOffset > 0 ? setFixedNav(true) : setFixedNav(false)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])
  return (
    <Container isFixed={fixedNav}>
      <LogoWrap href="#top">
        <StyledLogo isDark={fixedNav} />
      </LogoWrap>
      <List className="u-hide-medium-down">
        <Item isDark={fixedNav} offset={navHeight} href="#about">
          About
        </Item>
        <Item isDark={fixedNav} offset={navHeight} href="#portfolio">
          Portfolio
        </Item>
        <Item isDark={fixedNav} offset={navHeight} href="#team">
          Team
        </Item>
        <OffsiteItem isDark={fixedNav} offset={navHeight} href="https://scifivc.substack.com/" target="_blank">
          Blog
        </OffsiteItem>
        <Item isDark={fixedNav} offset={navHeight} href="#contact">
          Contact
        </Item>
      </List>
      <Button theme="primary" href="mailto:pitch@scifi.vc" size="small">
        Inquire
      </Button>
    </Container>
  )
}

export default Nav

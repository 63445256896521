import React from 'react'
import Helmet from 'react-helmet'
import { string } from 'prop-types'

import ShareImage from 'assets/images/share-image.jpg'

const MetaTags = ({ title }) => (
  <Helmet>
    {/* Title + description */}
    <title>{title}</title>
    <meta
      name="description"
      content="We’re an early stage generalist firm with emphasis on fintech, marketplaces, and science."
    />

    {/* Facebook */}
    <meta property="og:title" content={title} />
    <meta
      property="og:description"
      content="We’re an early stage generalist firm with emphasis on fintech, marketplaces, and science."
    />
    <meta property="og:type" content="website" key="og:type" />

    <meta property="og:url" content="https://scifi.vc/" key="og:url" />
    <meta property="og:image" content={ShareImage} key="og:image" />

    {/* Twitter */}
    <meta
      name="twitter:card"
      content="summary_large_image"
      key="twitter:card"
    />
    <meta name="twitter:url" content="https://scifi.vc/" key="twitter:url" />
    <meta name="twitter:title" content={title} />
    <meta
      name="twitter:description"
      content="We’re an early stage generalist firm with emphasis on fintech, marketplaces, and science."
    />
    <meta name="twitter:image" content={ShareImage} key="twitter:image" />
  </Helmet>
)

MetaTags.propTypes = {
  title: string.isRequired,
}

export default MetaTags

import React from 'react'
import styled from 'styled-components'
import LazyLoad from 'react-lazyload'

import { borderColor } from 'styles/colors'
import { media } from 'styles/media'
import { animationTime, animationCurve } from 'styles/variables'
import { cover } from 'styles/helpers'
import * as spacing from 'styles/spacing'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${spacing.medium};

  ${media.small`
    grid-template-columns: repeat(3, 1fr);
  `};

  ${media.medium`
    grid-gap: ${spacing.large};
    grid-template-columns: repeat(4, 1fr);
  `};

  ${media.large`
    grid-gap: ${spacing.xLarge};
  `};

  ${media.xLarge`
    grid-gap: ${spacing.xxLarge};
  `};
`

const Item = styled.a`
  position: relative;
  display: block;
  border: 1px solid ${borderColor};

  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
  }
`

const Logo = styled.img`
  ${cover('absolute')};
  object-fit: cover;
  transition: opacity ${animationTime} ${animationCurve};
  backface-visibility: hidden;

  ${Item}:hover & {
    opacity: 0.8;
  }
`

const LogoWall = ({ logos }) => (
  <Container>
    {logos.map((logo, i) => (
      <Item key={i} href={logo.link} target="_blank">
        <LazyLoad>
          <Logo
            src={require(`assets/images/portfolio/${logo.name}.png`)}
            alt={`${logo.name} logo`}
          />
        </LazyLoad>
      </Item>
    ))}
  </Container>
)

export default LogoWall

// Animation
export const animationTime = '0.3s'
export const animationCurve = 'cubic-bezier(0.785, 0.135, 0.150, 0.860)'

// Border Radius
export const borderRadius = '8px'

// Inputs
export const inputHeight = '50px'
export const inputHeightDesktop = '60px'
export const inputHeightSmall = '36px'

// Nav
export const navHeightMobile = '72px'
export const navHeight = '100px'

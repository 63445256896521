import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'
import { stripUnit, rgba } from 'polished'
import LazyLoad from 'react-lazyload'

import * as spacing from 'styles/spacing'
import { media } from 'styles/media'
import { grey } from 'styles/colors'

import Heading from 'components/Heading'

const Container = styled.div`
  display: block;
`

const IconWrap = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-bottom: ${spacing.medium};
  height: 48px;

  ${media.medium`
    height: 56px;
  `};

  ${media.xLarge`
    margin-bottom: ${stripUnit(spacing.medium) * 1.25 + 'px'};
  `};
`

const StyledHeading = styled(Heading)`
  position: static;

  ${media.large`
    margin-bottom: ${spacing.medium};
  `};
`

const Icon = styled.img`
  height: 100%;
  width: auto;
`

const Content = styled.div`
  position: static;
`

const Description = styled.p`
  color: ${rgba(grey, 0.75)};
`

const FocusArea = ({ icon, name, description }) => (
  <Container>
    <IconWrap>
      <LazyLoad>
        <Icon
          src={require(`assets/images/focus/${icon}.png`)}
          alt={`${name} illustration`}
        />
      </LazyLoad>
    </IconWrap>
    <Content>
      <StyledHeading size={3}>{name}</StyledHeading>
      <Description>{description}</Description>
    </Content>
  </Container>
)

FocusArea.propTypes = {
  icon: string.isRequired,
  name: string.isRequired,
  description: string.isRequired,
}

export default FocusArea

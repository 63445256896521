// Primary

export const blue = '#79A0C6'
export const orange = '#EF6630'

// Greys

export const greyDark = '#2D3D51'
export const grey = '#2D3D51'
export const greyLight = '#4F7597'
export const greyLightest = '#e2ecf5'
export const offWhite = '#F0F9FE'

// Misc

export const white = '#fff'
export const black = '#000'

// Set colors

export const primaryColor = blue
export const backgroundColor = offWhite
export const borderColor = greyLightest

import React from 'react'
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll'

import Wrapper from 'components/Wrapper'
import Section, { SectionBlock } from 'components/Section'
import LogoWall from 'components/LogoWall'
import Heading from 'components/Heading'

const Container = styled.div`
  position: static;
`

const Portfolio = () => (
  <Container id="portfolio">
    <Section flushBottom>
      <Wrapper>
        <SectionBlock>
          <ScrollAnimation animateIn="fadeInUp" offset={0} animateOnce>
            <Heading size={5}>Portfolio</Heading>
          </ScrollAnimation>
        </SectionBlock>

        <SectionBlock>
          <ScrollAnimation
            animateIn="fadeInUp"
            offset={0}
            delay={200}
            animateOnce
          >
            <LogoWall
              logos={[
                {
                  name: 'aalto',
                  link: 'https://aalto.com/',
                },
                {
                  name: 'agentsync',
                  link: 'https://agentsync.io/',
                },
                {
                  name: 'anchorage',
                  link: 'https://anchorage.com/',
                },
                {
                  name: 'appboxo',
                  link: 'https://appboxo.com/',
                },
                {
                  name: 'aven',
                  link: 'https://aven.com/',
                },
                {
                  name: 'backflip',
                  link: 'https://www.backflip.com/',
                },
                {
                  name: 'balance',
                  link: 'https://getbalance.com/',
                },
                {
                  name: 'blend',
                  link: 'https://blend.com/',
                },
                {
                  name: 'bolt-threads',
                  link: 'https://boltthreads.com/',
                },
                {
                  name: 'brex',
                  link: 'https://brex.com/',
                },
                {
                  name: 'capchase',
                  link: 'https://www.capchase.com/',
                },
                {
                  name: 'catch',
                  link: 'https://www.getcatch.com/',
                },
                {
                  name: 'cleartax',
                  link: 'https://cleartax.in/',
                },
                {
                  name: 'clipboard',
                  link: 'https://clipboardhealth.com/',
                },
                {
                  name: 'cloudtrucks',
                  link: 'https://cloudtrucks.com/',
                },
                {
                  name: 'coast',
                  link: 'https://www.coastpay.com/',
                },
                {
                  name: 'coinbase',
                  link: 'https://www.coinbase.com/',
                },
                {
                  name: 'collective-health',
                  link: 'https://collectivehealth.com/',
                },
                {
                  name: 'color',
                  link: 'https://www.color.com/',
                },
                {
                  name: 'compound',
                  link: 'https://www.withcompound.com/',
                },
                {
                  name: 'copilot',
                  link: 'https://copilotsearch.com/',
                },
                {
                  name: 'divvy',
                  link: 'https://www.divvyhomes.com/',
                },
                {
                  name: 'elemental',
                  link: 'https://elementalmachines.io/',
                },
                {
                  name: 'ellevest',
                  link: 'https://www.ellevest.com/',
                },
                {
                  name: 'ecl',
                  link: 'https://www.emeraldcloudlab.com/',
                },
                {
                  name: 'empora',
                  link: 'https://www.emporatitle.com/',
                },
                {
                  name: 'emulate',
                  link: 'https://www.emulatebio.com/',
                },
                {
                  name: 'ethyca',
                  link: 'https://www.ethyca.com/',
                },
                {
                  name: 'freeform',
                  link: 'https://www.freeform.co/',
                },
                {
                  name: 'ganymede',
                  link: 'https://ganymede.bio/',
                },
                {
                  name: 'ggwp',
                  link: 'https://ggwp.com/',
                },
                {
                  name: 'gusto',
                  link: 'https://gusto.com/',
                },
                {
                  name: 'highlight',
                  link: 'https://highlight.xyz/',
                },
                {
                  name: 'human-api',
                  link: 'https://www.humanapi.co/',
                },
                {
                  name: 'kalo',
                  link: 'https://kalohq.com/',
                },
                {
                  name: 'loft',
                  link: 'https://www.loft.com.br/',
                },
                {
                  name: 'mixpanel',
                  link: 'https://mixpanel.com/',
                },
                {
                  name: 'o1',
                  link: 'https://o1labs.org/',
                },
                {
                  name: 'opendoor',
                  link: 'https://www.opendoor.com/',
                },
                {
                  name: 'pace',
                  link: 'https://www.pace.group/',
                },
                {
                  name: 'pathpoint',
                  link: 'https://www.pathpoint.com/',
                },
                {
                  name: 'peach',
                  link: 'https://www.peachfinance.com/',
                },
                {
                  name: 'peloton',
                  link: 'https://www.onepeloton.com/',
                },
                {
                  name: 'pinterest',
                  link: 'https://www.pinterest.com/',
                },
                {
                  name: 'pomelo',
                  link: 'https://pomelo.la/',
                },
                {
                  name: 'prizepool',
                  link: 'https://getprizepool.com/',
                },
                {
                  name: 'propel',
                  link: 'https://www.joinpropel.com/',
                },
                {
                  name: 'psiquantum',
                  link: 'https://psiquantum.com/',
                },
                {
                  name: 'q',
                  link: 'https://q.bio/',
                },
                {
                  name: 'red-planet',
                  link: 'https://redplanetlabs.com/',
                },
                {
                  name: 'sentilink',
                  link: 'https://sentilink.com/',
                },
                {
                  name: 'settle',
                  link: 'https://settle.co/',
                },
                {
                  name: 'shoppinggives',
                  link: 'https://shoppinggives.com/',
                },
                {
                  name: 'sift',
                  link: 'https://sift.com/',
                },
                {
                  name: 'smithrx',
                  link: 'https://smithrx.com/',
                },
                {
                  name: 'solvebio',
                  link: 'https://www.solvebio.com/',
                },
                {
                  name: 'spring',
                  link: 'https://www.springdiscovery.com/',
                },
                {
                  name: 'storytime',
                  link: 'https://www.storytime.land/',
                },
                {
                  name: 'stripe',
                  link: 'https://stripe.com/',
                },                
                {
                  name: 'supergood',
                  link: 'https://supergood.ai/',
                },
                {
                  name: 'swiftlane',
                  link: 'https://swiftlane.com/',
                },
                {
                  name: 'synctera',
                  link: 'https://www.synctera.com/',
                },
                {
                  name: 'synthego',
                  link: 'https://www.synthego.com/',
                },
                {
                  name: 'teller',
                  link: 'https://teller.io/',
                },
                {
                  name: 'traba',
                  link: 'https://traba.work/',
                },
                {
                  name: 'transferwise',
                  link: 'https://transferwise.com/',
                },
                {
                  name: 'trueaccord',
                  link: 'https://www.trueaccord.com/',
                },
                {
                  name: 'trynow',
                  link: 'https://www.trynow.io/',
                },
                {
                  name: 'uber',
                  link: 'https://www.uber.com/',
                },
                {
                  name: 'unity',
                  link: 'https://unity.com/',
                },
                {
                  name: 'very-good-security',
                  link: 'https://www.verygoodsecurity.com/',
                },
                {
                  name: 'virta',
                  link: 'https://www.virtahealth.com/',
                },
                {
                  name: 'withco',
                  link: 'https://www.with.co/',
                },
                {
                  name: 'workstream',
                  link: 'https://www.workstream.is/',
                },
                {
                  name: 'x1card',
                  link: 'https://x1creditcard.com/',
                },
                {
                  name: 'zymergen',
                  link: 'https://www.zymergen.com/',
                },
              ]}
            />
          </ScrollAnimation>
        </SectionBlock>
      </Wrapper>
    </Section>
  </Container>
)

export default Portfolio
